import React, {Component} from 'react';
import { Typography } from '@mui/material';
class Title extends Component {

  render() {
    return (
      <div>
        <Typography variant="h4" sx={{fontWeight: 20, paddingLeft: 5}}>Elliot Barnwell</Typography>
      </div>
    );
  }
}

export default Title
